<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-12">
        <h1>Admin - Manage BOQs</h1>
        <v-form @submit.prevent="getBoqs">
          <v-row>
            <v-col class="col-3">
              <v-text-field
                label="Search"
                v-model="searchTerm"></v-text-field>
            </v-col>
            <v-col class="col-3">
              <v-btn type="submit">Search</v-btn>
              &nbsp;
              <v-btn @click="clearSearch">Clear</v-btn>
            </v-col>
            <v-col class="col-6 text-right">
              <router-link to="/admin/boqs/view-changes" v-if="userid === 107 || userid === 108 || userid === 111">
                <v-btn>BOQ changes</v-btn>
              </router-link>
              &nbsp;
              <router-link to="/admin/boqs/update-prices" v-if="userid === 107 || userid === 108 || userid === 111">
                <v-btn>Update all BOQ prices</v-btn>
              </router-link>
              &nbsp;
              <router-link to="/admin/boqs/add">
                <v-btn>Add new BOQ</v-btn>
              </router-link>
            </v-col>
          </v-row>
        </v-form>
        <v-row>
          <v-col class="col-12">
            <v-simple-table
              dense
              fixed-header
              height="450px"
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Description</th>
                    <th class="text-left">Units</th>
                    <th class="text-left">Standard Cost</th>
                    <th class="text-left">Bonus Rate</th>
                    <th class="text-left">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(boq, index) in boqs"
                    :key="boq.id"
                  >
                    <td>{{ boq.description }}</td>
                    <td>{{ boq.units }}</td>
                    <td>{{ boq.standard_cost | priceInPounds | currency }}</td>
                    <td>{{ boq.bonusRate | priceInPounds | currency }}</td>
                    <td>
                      <v-btn @click="edit(index)" x-small>Edit</v-btn>
                      &nbsp;
                      <v-btn @click="archive(index)" x-small>Archive</v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-pagination
              class="pt-4"
              v-model="page"
              :length="length"
              prev-icon="mdi-menu-left"
              next-icon="mdi-menu-right"
              :total-visible="totalVisible"
            ></v-pagination>
            </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
  
<script>
import axios from '../../../axios';

export default {
  name: 'AdminBoqIndex',
  computed: {
    token() {
      return this.$store.state.token;
    },
    userid() {
      return this.$store.state.userid;
    },
  },
  data() {
    return {
      boqs: [],
      page: 1,
      length: 0,
      totalVisible: 9,
      searchTerm: '',
    };
  },
  watch: {
    page() {
      this.getBoqs();
    },
  },
  methods: {
    archive(index) {
      let boq = {};
      boq = this.boqs[index].id;
      axios.get(`/boqs/archive/${boq}.json?token=${this.token}&userid=${this.userid}`)
        .then((response) => {
          if (response.data === true) {
            this.boqs.splice(index, 1);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    edit(index) {
      let boq = {};
      boq = this.boqs[index].id;
      this.$router.push(`/admin/boqs/edit/${boq}`);
    },
    clearSearch() {
      this.searchTerm = '';
      this.getBoqs();
    },
    getBoqs() {
      this.boqs = [];
      this.favouriteBoqs = [];
      axios.get(`/boqs/getAll/${this.page}.json?token=${this.token}&term=${this.searchTerm}&userid=${this.userid}`)
        .then((response) => {
          this.boqs = response.data.boqs;
          this.favouriteBoqs = response.data.favouriteBoqs;
          this.length = response.data.length;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getBoqs();
  },
};
</script>
